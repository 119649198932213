<script>
import { UNAME } from '@/store/mutation-types'
import axios from 'axios'
import Vue from 'vue'
import { bindUser } from '@/api/data'

export default {
  name: 'QrBind',
  data () {
    return {
      imgUrl: '',
      clientId: null,
      authCode: null,
      envMsg: this.$t('user.visit.tip')
    }
  },
  props: ['layerid'],
  created () {
    const _this = this
    this.initClient(true)
    setInterval(function () {
      _this.refreshQr()
    }, 60000)
  },
  methods: {
    initClient (initSocket) {
      axios.post('/user/qr/client', { action: 'share', name: Vue.ls.get(UNAME) }).then(res => {
        const resp = res.data
        if (resp.code === 0) {
          const clientId = resp.data.clientId
          const authCode = resp.data.authCode
          this.clientId = clientId
          this.authCode = authCode
          if (initSocket) {
            this.initWebSocket(clientId, authCode)
          }
          this.refreshQr()
        }
      })
    },
    refreshQr () {
      this.imgUrl = `/user/auth/qr?action=share&clientId=${this.clientId}&authCode=${this.authCode}&time=${(new Date()).getTime()}`
    },
    initWebSocket (clientId, authCode) {
      const _this = this
      var socket = new WebSocket(`wss://www.breathmeters.net:444/qr/share/${clientId}/${authCode}`)
      socket.onopen = function (event) {
        socket.onmessage = function (event) {
          const recMsg = JSON.parse(event.data)
          switch (recMsg.event) {
            case 'scaned':
              _this.envMsg = recMsg.msg.ja
              break
            case 'auth':
              _this.envMsg = recMsg.msg.ja
              if (recMsg.result === 'accept') {
                bindUser(recMsg.data).then(res => {
                  _this.$message.success(_this.$t('user.action.bind.success'))
                  _this.$parent.refreshTable()
                  _this.$layer.close(_this.layerid)
})
              }
              break
          }
        }
      }
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 16px;">
    <div style="text-align: center;">
      <div style="color: #008df6;">{{ $t('user.action.qrWinOpenTip') }}</div>
      <div style="margin-top: 8px;padding: 16px;border: 1px solid #666;border-radius: 8px;height:200px;">
        <img :src="imgUrl" class="qr-img" v-if="imgUrl"/>
        <div v-else>
          <div style="text-align: center;padding-top: 70px;"><a-spin /></div>
        </div>
      </div>
      <div style="text-align: center;margin-top: 8px;">{{ envMsg }}</div>
    </div>

  </div>
</template>

<style scoped lang='less'>
.qr-img {
  width: 160px;
  height: 160px;
}
</style>
