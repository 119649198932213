<script>
export default {
  name: 'EmailBind'
}
</script>

<template>

</template>

<style scoped lang='less'>

</style>
