<script>
import { loadKeiyoUserSetting } from '@/api/data'

export default {
  name: 'UserProfile',
  props: ['uid', 'layerid'],
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    this.$nextTick(function () {
      loadKeiyoUserSetting({ uid: this.uid }).then(res => {
         if (res.code === 0) {
           this.form.setFieldsValue(res.data)
         }
      })
    })
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <a-row>
      <a-col :span="8">
        <img src="/images/carsn.jpg" style="width: 100%;"/>
      </a-col>
      <a-col :span="16">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-form-item :label="$t('user.name')">
            <a-input
              disabled
              v-decorator="['name', { rules: [{ required: false, message: '' }] }]"
            />
          </a-form-item>
          <a-form-item label="Id">
            <a-input
              disabled
              v-decorator="['id', { rules: [{ required: false, message: '' }] }]"
            />
          </a-form-item>
          <a-form-item :label="$t('user.car.area')">
            <a-input
              disabled
              v-decorator="['area', { rules: [{ required: false, message: '' }] }]"
            />
          </a-form-item>
          <a-form-item :label="$t('user.car.type')">
            <a-input
              disabled
              v-decorator="['type', { rules: [{ required: false, message: '' }] }]"
            />
          </a-form-item>
          <a-form-item :label="$t('user.car.plateName')">
            <a-input
              disabled
              v-decorator="['plateName', { rules: [{ required: false, message: '' }] }]"
            />
          </a-form-item>
          <a-form-item :label="$t('user.car.number')">
            <a-input
              disabled
              v-decorator="['number', { rules: [{ required: false, message: '' }] }]"
            />
          </a-form-item>
          <a-form-item :label="$t('user.car.color')">
            <a-select
              disabled
              v-decorator="[
                'color',
                { rules: [{ required: true, message: '' }] },
              ]"
            >
              <a-select-option value="white_green">
                {{ $t('user.car.color.GreenWhite') }}
              </a-select-option>
              <a-select-option value="green_white">
                {{ $t('user.car.color.WhiteGreen') }}
              </a-select-option>
              <a-select-option value="yellow_black">
                {{ $t('user.car.color.BlackYellow') }}
              </a-select-option>
              <a-select-option value="black_yellow">
                {{ $t('user.car.color.YellowBlack') }}
              </a-select-option>
              <a-select-option value="blue_green">
                {{ $t('user.car.color.GreenBlue') }}
              </a-select-option>
              <a-select-option value="white_red">
                {{ $t('user.car.color.RedWhite') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<style scoped lang='less'>

</style>
